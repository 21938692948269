.orderItemDetailsValue {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
}

.orderItemDetailsLabel {
    font-size: 13px;
    font-weight: 400;
}

.assemblyPriceBreakdownBox {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid #777;
    padding: 7px 12px 0 12px;
    max-width: 248px;
    margin-bottom: 10px;
}