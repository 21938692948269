


.publicWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#42576c",endColorstr="#001529",GradientType=1);
  background: linear-gradient(#354045, #3d474d);
  /*background: rgb(41,51,61);*/
  /*background: radial-gradient(circle, rgba(41,51,61,1) 0%, rgba(0,21,41,1) 102%);*/
}

.tiledBackgroundWide {
  background: url("/src/assets/vectors/waves_01_des.svg") no-repeat 50% 100%, url("/src/assets/vectors/waves_02_des.svg") no-repeat 50% 35%;
  background-size: auto, 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.tiledBackgroundNarrow {
  background: url("/src/assets/vectors/waves_01_des.svg") no-repeat 50% 100%, url("/src/assets/vectors/waves_02_des.svg") no-repeat 50% 0%;
  background-size: auto, 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.publicCard {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 450px;
  width: 500px;
  box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.10);
  /*border-radius: 6px;*/
  border: 1px solid #eaeaea; /*#eaeaea*/
  bottom: 4%;
  background: #ffffff;
}

.loginTitle {
  font-weight: 600;
  font-size: 2.2rem;
  color: white;
}

.loginTitleBlack {
  font-weight: 600;
  font-size: 2.2rem;
  color: black !important;
}

.signInText {
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
}

.resendText {
  font-weight: 500;
  font-size: 1.45rem;
  color: black !important;
}

.loginLabel {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.loginText {
  font-weight: 600;
  background: transparent;
  border: none;
  box-shadow: none;
}
.loginText > span {
  color: black;
}

.loginText:hover > span {
  color: white;
}

.loginText > label > span {
  color: white;
}

.loginInputText.ant-input-affix-wrapper {
  color: #333f48 !important;
  font-weight: 600 !important;
  border: 1px solid #ced4da;
  background-color: white !important;
}
.loginInputText > .ant-input-prefix > .anticon,
.loginInputText > .ant-input-suffix > .anticon,
.loginInputText > input {
  color: #0b0e0f !important;
}
.loginInputText.ant-input-affix-wrapper:hover {
  color: #333f48;
  font-weight: 600 !important;
  border: 1px solid #ced4da !important;
}
.loginInputText.ant-input-affix-wrapper:active {
  border: 1px solid #59626d !important;
  box-shadow: 0 0 0 2px #c7c7c8;
}
.loginInputText.ant-input-affix-wrapper:focus,
.loginInputText.ant-input-affix-wrapper:focus,
.loginInputText.ant-input-affix-wrapper-focused,
.loginInputText.ant-input-affix-wrapper-focused:hover {
  box-shadow: 0 0 0 2px #c7c7c8;
  border: 1px solid #59626d !important;
  border-right-width: 1px !important;
}
.loginInputText > .ant-input {
  background-color: white !important;
}



.ant-input {
  color: #333f48;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
}

.ant-input-number-input {
  color: #333f48;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
}

.ant-form-item-explain-error > div {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
}

.ant-form-item-explain > div {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.ant-form-item-explain-error {
  min-height: 0 !important;
  max-height: 20px !important;
}

.ant-form-item-control {
  max-height: 52px;
}

.messageText {
  font-size: 14px;
  font-weight: 600;
}

:-webkit-autofill { /* -webkit also works in firefox here */
  filter:none; /* needed for firefox! */
  box-shadow: 0 0 0 100px rgba(173, 172, 172, 0.21) inset;
}

